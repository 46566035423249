// Filename - App.js

import React from "react";
import Switcher from "./components/Switcher";
import SideNav from "./components/SideNav";
import Note from "./components/Note";
import { useState, useEffect } from "react";
import { Editor, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';

function App() {
  const [notes, setNotes] = useState(["Tisdag - 21/11/23", "Måndag - 20/11/23", "Söndag - 19/11/23", "Lördag - 18/11/23", "Fredag - 17/11/23", "Torsdag - 16/11/23", "Onsdag - 15/11/23"]);
  const [selectedNote, setSelectedNote] = useState(0);
  const [noteData, setNoteData] = useState({});
  const [editorState, setEditorState] = React.useState(
    () => EditorState.createEmpty(),
  );
  const [previousNote, setPreviousNote] = useState(0);





  useEffect(() => {
    setPreviousNote(selectedNote);
    if (previousNote !== selectedNote) {
      setNoteData({ ...noteData, [previousNote]: editorState });
    }
    if (noteData[selectedNote]) {
      console.log("INNE I IF")
      setEditorState(noteData[selectedNote]);
    }
    else {
      setEditorState(EditorState.createEmpty());
    }
  }, [selectedNote]);


  return (
    <div className=" bg-slate-200 dark:bg-slate-800 flex h-screen">
      <div className="absolute right-5 top-5"><Switcher /></div>
      <SideNav notes={notes} setSelectedNote={setSelectedNote} />
      <div className="flex-grow flex justify-center">
        <Note note={notes[selectedNote]} editorState={editorState} setEditorState={setEditorState} />
      </div>


    </div>
  );
}

export default App;
