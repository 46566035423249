import ReactDOM from 'react-dom';
import {Editor, EditorState} from 'draft-js';
import 'draft-js/dist/Draft.css';
import React from 'react';

export default function Note ({note, editorState, setEditorState}) {
   
    return (
        <div className="bg-slate-100 w-2/3 rounded-xl m-5 p-5">
            <h2 className="font-mono text-lg p-3 text-center">{note}</h2>
            <Editor editorState={editorState} onChange={setEditorState} />
        </div>
    )
}