import { useState } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Button from '@mui/material/Button';
import Switcher from "./Switcher";
import DeleteIcon from '@mui/icons-material/Delete';

export default function SideNav({setSelectedNote, notes}) {

    const [showPopup, setShowPopup] = useState(false);
    const createNewNote = () => {
        let newDate = new Date();
        console.log(newDate.toDateString());

    }
    return (
        <div className="flex-none w-60 items-center rounded-e-md bg-slate-100 dark:bg-slate-400 flex-col p-2">
            
            <h2 className="font-mono text-lg p-3 dark:text-white text-center">Min dagbok</h2>
            <div className="flex flex-col w-full">
                <div className=" rounded-md m-1.5 flex justify-center">
                    <Button style={{}} onClick={createNewNote} variant="outlined">Skapa ny dagbok</Button>
                </div>
                {notes.map((note, index) => (
                    <div className="bg-gray-400 dark:bg-slate-200 rounded-md m-1.5 flex justify-center">
                        <Button onClick={()=>setSelectedNote(index)} variant="text">{note}</Button>
                    </div>))}
            </div>
        </div>
    )
}
